import {
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LanguageSelect } from '../components';
import { ArrowForward } from '../components/icons/ArrowForward';
import { UnoHealthLogo } from '../components/svgs/UnoHealthLogo';
import { usePageData } from '../hooks/usePageData';
import { renderPage } from '../renderPage';

const DEFAULT_FONT_SIZE = '1.125rem'; // Increase font size from default on other pages (16px) b/c this a text-heavy page

const ProgramsTable = (): JSX.Element => {
  const { t } = useTranslation(['common', 'index']);
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('program.programWord', 'Program', { ns: 'common' })}</TableCell>
            <TableCell>{t('programTable.descriptionHeader', 'What it does', { ns: 'index' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{t('program.medicaid.name', 'Medicaid', { ns: 'common' })}</TableCell>
            <TableCell>
              {t(
                'programTable.medicaid.description',
                'Bring your health care costs close to $0 while getting extra coverage for in-home care',
                { ns: 'index' }
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t('program.msp.nameWithAbbreviation', 'Medicare Savings Program (MSP)', { ns: 'common' })}
            </TableCell>
            <TableCell>
              {t('programTable.msp.description', 'Get $2,041 back each year from Social Security.', { ns: 'index' })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t('program.spap.name', 'State Based Pharmaceutical Assistance Programs', { ns: 'common' })}
            </TableCell>
            <TableCell>
              {t('programTable.spap.description', 'Pay no more than $20 for covered prescriptions.', { ns: 'index' })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('program.snap.name', 'SNAP', { ns: 'common' })}</TableCell>
            <TableCell>
              {t('programTable.snap.description', 'Get up to $200/month ($2,400 per year) to spend on groceries.', {
                ns: 'index',
              })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('program.lis.nameWithAbbreviation', 'Low Income Subsidy (LIS)', { ns: 'common' })}</TableCell>
            <TableCell>
              {t(
                'programTable.lis.description',
                'Pay less for covered prescriptions. Many people pay no more than $9.85 for prescriptions with this program.',
                { ns: 'index' }
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('program.lifeline.name', 'Lifeline', { ns: 'common' })}</TableCell>
            <TableCell>
              {t('programTable.lifeline.description', 'Save over $100 a year on your phone and broadband bills.', {
                ns: 'index',
              })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('program.liheap.name', 'LIHEAP', { ns: 'common' })}</TableCell>
            <TableCell>
              {t('programTable.liheap.description', 'Save up to $350 a year on your heating bills.', { ns: 'index' })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const getFAQs: (t: TFunction) => Map<string, ReactNode> = (t) =>
  new Map<string, ReactNode>([
    [
      t('faqs.costOfService.question', "What's the cost of this service?", { ns: 'index' }),
      t(
        'faqs.costOfService.answer',
        'Working with Uno is completely free for you. Your insurance plan provides this service for you as part of your plan benefits.',
        { ns: 'index' }
      ),
    ],
    [
      t('faqs.relationshipToPlan.question', 'What is your relationship to my plan?', { ns: 'index' }),
      <Typography fontSize={DEFAULT_FONT_SIZE}>
        <Trans i18nKey="faqs.relationshipToPlan.answer" ns="index">
          Your plan works with Uno to help you access all the benefits and programs you are eligible for. Your plan
          prioritizes your health and well-being; that's why they've contracted Uno to make sure all aspects of your
          health are taken care of, from being able to pay for prescriptions to getting extra money in your pocket to
          afford the things that are important to you. <br />
          <br />
          If you have any concerns about Uno or if Uno works with your plan, we recommend calling your plan for more
          information.
        </Trans>
        ,
      </Typography>,
    ],
    [
      t('faqs.willUnoChangeMyCoverage.question', 'Will working with Uno change my plan coverage?', { ns: 'index' }),
      t(
        'faqs.willUnoChangeMyCoverage.answer',
        'No, working with Uno will not change your plan coverage or the benefits you already have.',
        { ns: 'index' }
      ),
    ],
    [
      t(
        'faqs.willIHaveToVerifyInfo.question',
        'Why do I have to verify my own information? Why do I need to confirm my address and date of birth?',
        { ns: 'index' }
      ),
      t(
        'faqs.willIHaveToVerifyInfo.answer',
        "To protect your privacy, Uno's enrollment specialists will ask you to confirm your name and address on every phone call. If you have any concerns about sharing your information with Uno enrollment specialists, we recommend calling your plan. They will be able to confirm they are working with Uno to bring you valuable savings.",
        { ns: 'index' }
      ),
    ],
    [
      t('faqs.whatKindsOfProgramsCanIEnrollIn.question', 'What kinds of programs can you help me get enrolled in?', {
        ns: 'index',
      }),
      <>
        <Typography fontSize={DEFAULT_FONT_SIZE}>
          {t(
            'faqs.whatKindsOfProgramsCanIEnrollIn.answer',
            'Uno helps you apply for programs that span a broad range of benefits and needs. See the table below for a summary of each program Uno can help you access:',
            { ns: 'index' }
          )}
        </Typography>
        <ProgramsTable />
      </>,
    ],
    [
      t('faqs.whyIsPartnerInformationNeeded.question', "Why do you need my spouse or partner's information?", {
        ns: 'index',
      }),
      t(
        'faqs.whyIsPartnerInformationNeeded.answer',
        'Some programs, like SNAP, consider eligibility at the household level, and require information for each individual who may be part of that household. For other programs, Uno has found that programs offer more savings when you apply as a couple or household.',
        { ns: 'index' }
      ),
    ],
    [
      t(
        'faqs.whatAlternativeDocumentsCanIUse.question',
        "What documents can I use if I don't have the ones requested?",
        { ns: 'index' }
      ),
      t(
        'faqs.whatAlternativeDocumentsCanIUse.answer',
        "Our enrollment team can help you identify alternative documents if you don't have the ones requested.",
        { ns: 'index' }
      ),
    ],
    [
      t(
        'faqs.IAlreadyHaveARepresentative.question',
        "I already have a representative; why can't they apply to these programs for me?",
        { ns: 'index' }
      ),
      t(
        'faqs.IAlreadyHaveARepresentative.answer',
        'If you already have an authorized representative or someone who helps coordinate your care, we can work with them to get you access to these benefits. We know that managing care can be complicated. Our goal is to make this process as easy as possible for you and your family. We have built years of experience in understanding how to access these benefit programs; by working with us, you and your representative will save time and worry.',
        { ns: 'index' }
      ),
    ],
    [
      t('faqs.whereAreYouLocated.question', 'Where are you located?', { ns: 'index' }),
      t(
        'faqs.whereAreYouLocated.answer',
        'Uno Health is a New York based company, but we have benefit experts all over the country and support enrollment across many states.',
        { ns: 'index' }
      ),
    ],
    [
      t('faqs.whatIsYourPrivacyPolicy.question', 'What is your privacy policy?', { ns: 'index' }),
      <>
        <Typography>
          <Trans i18nKey="faqs.whatIsYourPrivacyPolicy.answer" ns="index">
            This privacy policy for our enrollment service can be viewed in full{' '}
            <Link href="/enrollment-privacy-policy">here</Link> , the privacy policy for this website can be viewed in
            full <Link href="/website-privacy-policy">here</Link> , and our terms of service can be viewed in full{' '}
            <Link href="/terms-of-service">here</Link> .
          </Trans>
        </Typography>
      </>,
    ],
  ]);

const Index = (): JSX.Element => {
  const {
    user: { isLoggedIn },
  } = usePageData();

  const { t } = useTranslation(['index', 'common']);
  const FAQS = getFAQs(t);

  return (
    <div className="fs-unmask">
      <Grid
        container
        sx={{
          alignItems: 'center',
          bgcolor: 'background.default',
          p: 2,
        }}
      >
        <Grid item xs={4} />
        <Grid item xs={4} />
        <Grid item xs={4}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="end">
            <LanguageSelect />
            <Button
              component="a"
              href={isLoggedIn ? '/benefits-status' : '/login'}
              variant="outlined"
              sx={{ textAlign: 'center' }}
            >
              {isLoggedIn ? t('common:buttons.viewPortal', 'View portal') : t('common:buttons.signIn', 'Sign in')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Container maxWidth="md" sx={{ my: 4 }}>
        <Stack spacing={4} sx={{ alignItems: 'center', mb: 8 }}>
          <UnoHealthLogo width={100} />
          <Typography component="h1" variant="h2" textAlign="center">
            {t('subHeader', 'Helping you enroll in the benefits you deserve', { ns: 'index' })}
          </Typography>
          <Typography fontSize={DEFAULT_FONT_SIZE} textAlign="center">
            {t(
              'aboutUno',
              'Uno helps Medicare members like you access a range of benefits, from lowering the costs of your prescription medications to putting money back in your pocket for health care and food.',
              { ns: 'index' }
            )}
          </Typography>
          <Button
            fullWidth
            component="a"
            href={isLoggedIn ? '/benefits-status' : '/login'}
            endIcon={<ArrowForward sx={(theme) => ({ fill: theme.palette.text4.main })} />}
            sx={{ justifyContent: 'space-between' }}
          >
            {isLoggedIn
              ? t('cta.viewYourPortal', 'View your member portal', { ns: 'index' })
              : t('cta.signInToPortal', 'Sign into your member portal', { ns: 'index' })}
          </Button>
          <Typography variant="h2" textAlign="center">
            {t('faqs.header', 'Frequently Asked Questions', { ns: 'index' })}
          </Typography>
          <Typography component="ol">
            <Stack spacing={1}>
              {[...FAQS.keys()].map((question, i) => (
                <li key={i}>{question}</li>
              ))}
            </Stack>
          </Typography>
          <Typography component="ol">
            <Stack spacing={4}>
              {[...FAQS.keys()].map((question, i) => (
                <Stack key={i} spacing={2}>
                  <Typography component="li" variant="h3">
                    {question}
                  </Typography>
                  <>
                    {typeof FAQS.get(question) === 'string' ? (
                      <Typography fontSize={DEFAULT_FONT_SIZE}>{FAQS.get(question)}</Typography>
                    ) : (
                      FAQS.get(question)
                    )}
                  </>
                </Stack>
              ))}
            </Stack>
          </Typography>
        </Stack>
      </Container>
    </div>
  );
};

renderPage('index', <Index />);
